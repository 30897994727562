document.addEventListener("DOMContentLoaded", function () {
  document.getElementById("hamburger").addEventListener("click", function () {
    document.getElementById("hamburger").classList.toggle("open");
    document.getElementById("slide").classList.toggle("show");
    document.querySelector(".header").classList.toggle("open");
    document.querySelector(".header__titleText").classList.toggle("open");
  });
});



window.addEventListener("load", function () {
  document.getElementById("loading").classList.add("hide");
});

document.querySelector(".header__lang-button").addEventListener("click", function () {
  this.classList.toggle("click");
  const langMenu = document.querySelector(".header__lang-selects");
  langMenu.classList.toggle("open");
});

document.querySelectorAll(".header__lang-select").forEach((select) => {
  select.addEventListener("click", function () {
    const langMenu = document.querySelector(".header__lang-selects");
    langMenu.classList.remove("open");
    document.querySelector(".header__lang-button").classList.remove("click");
  });
});

const header = document.querySelector(".header");
const home = document.getElementById("top");
const meguri = document.getElementById("meguri")
const hitotsu = document.getElementById("hitotsu")
const sekki = document.getElementById("sekki")
const sekkiProduct = document.getElementById("sekkiDetail")
const cart = document.getElementById("cart")

if (home) {
  document.querySelector(".reservation").classList.add("topReservation");

  const topFvItems = document.querySelectorAll(".topFv__item");

  topFvItems.forEach((item) => {
    item.addEventListener("mouseover", function () {
      topFvItems.forEach((item) => {
        item.querySelector(".topFv__hoverLink").classList.remove("active");
        item.classList.remove("blur");
        item.classList.remove("active");
      });

      item.querySelector(".topFv__hoverLink").classList.add("active");
      // もう片方のitemにblurクラスを追加
      const otherItem = item.nextElementSibling || item.previousElementSibling;
      otherItem.classList.add("blur");
      item.classList.add("active");
    });

    item.addEventListener("mouseout", function () {
      topFvItems.forEach((item) => {
        item.querySelector(".topFv__hoverLink").classList.remove("active");
        item.classList.remove("blur");
        item.classList.remove("active");
      });
    });
  });

  if (window.innerWidth < 768) {
    document.querySelector(".header__titleText").classList.add("show")
    document.querySelector(".header__titleText").classList.add("top");

    if (window.scrollY > 5) {
      document.querySelector(".header__titleText").classList.remove("show");
      document.querySelector(".header__titleText").classList.remove("top");
    }

    window.addEventListener("scroll", function () {
      const headerTitleText = document.querySelector(".header__titleText");
      if (window.scrollY > 5) {
        headerTitleText.classList.remove("show");
        headerTitleText.classList.remove("top");
      } else if (window.scrollY > 0) {
        headerTitleText.classList.remove("show");
        headerTitleText.classList.add("top");
      } else {
        headerTitleText.classList.add("show");
        headerTitleText.classList.add("top");
      }
    });
  }

  const fvSpSlide = new Swiper("#topFvSpSlide", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 3000,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });

  const hospitalilySlide = new Swiper("#topHospitalitySlide", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".topHospitality__button--prev",
      prevEl: ".topHospitality__button--next",
    },
  });

  const sekkiSlide = new Swiper("#topSekkiSlide", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    // autoplay: {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    speed: 3000,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".topSekki__slide-button--prev",
      prevEl: ".topSekki__slide-button--next",
    },
    pagination: {
      el: ".topSekki__slide-pagination",
      clickable: true,
    },
  });


}

if (meguri) {
  header.classList.add("open");

  if (window.innerWidth > 768) {
    header.classList.remove("open");
  }

  if (window.innerWidth < 768) {
    document.querySelector(".header__titleText").classList.add("show")
    document.querySelector(".header__titleText").classList.add("top");

    if (window.scrollY > 5) {
      document.querySelector(".header__titleText").classList.remove("show");
      document.querySelector(".header__titleText").classList.remove("top");
      header.classList.remove("open");
    }

    window.addEventListener("scroll", function () {
      const headerTitleText = document.querySelector(".header__titleText");
      if (window.scrollY > 5) {
        headerTitleText.classList.remove("show");
        headerTitleText.classList.remove("top");
        header.classList.remove("open");
      } else if (window.scrollY > 0) {
        headerTitleText.classList.remove("show");
        headerTitleText.classList.add("top");
        header.classList.add("open");
      } else {
        headerTitleText.classList.add("show");
        headerTitleText.classList.add("top");
      }
    });
  }


  const meguriSlide = new Swiper("#meguriSlide", {
    spaceBetween: 50,
    loop: true,
    loopAdditionalSlides: 1,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    navigation: {
      nextEl: ".meguriSlide__button--prev",
      prevEl: ".meguriSlide__button--next",
    },
  });

  const drinkButton = document.getElementById("meguriDrinkMore");

  drinkButton.addEventListener("click", function () {
    this.classList.toggle("open");
    console.log("click");
    const menu = document.querySelector(".meguriMenu__drink-menu-wrapper");
    menu.classList.toggle("open");

    if (this.classList.contains("open")) {
      console.log(menu.scrollHeight);
      menu.style.height = menu.scrollHeight + "px";
    } else {
      menu.style.height = 0;
    }
  });

  const moreButtons = document.querySelectorAll(".meguriMenu__more");

  moreButtons.forEach((button) => {
    button.addEventListener("click", function () {
      this.classList.toggle("open");
      const menu = this.nextElementSibling;
      menu.classList.toggle("open");
    })
  });

  const modalCloseButtons = document.querySelectorAll(".meguriMenuModal__close");

  modalCloseButtons.forEach((button) => {
    button.addEventListener("click", function () {
      const modal = this.closest(".meguriMenuModal");
      const more = modal.previousElementSibling;
      console.log(more);
      modal.classList.remove("open");
      more.classList.remove("open");
    });
  });

  const meguriDinnerSlide1 = new Swiper("#meguriDinner01", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".meguriDinner01--prev",
      prevEl: ".meguriDinner01--next",
    },
  });
  const meguriDinnerSlide2 = new Swiper("#meguriDinner02", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".meguriDinner02--prev",
      prevEl: ".meguriDinner02--next",
    },
  });
  const meguriLunchSlide1 = new Swiper("#meguriLunch01", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".meguriLunch01--prev",
      prevEl: ".meguriLunch01--next",
    },
  });
  const meguriLunchSlide2 = new Swiper("#meguriLunch02", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".meguriLunch02--prev",
      prevEl: ".meguriLunch02--next",
    },
  });
  const meguriNigiriSlide1 = new Swiper("#meguriNigiri01", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".meguriNigiri01--prev",
      prevEl: ".meguriNigiri02--next",
    },
  });
  const meguriNigiriSlide2 = new Swiper("#meguriNigiri02", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".meguriNigiri02--prev",
      prevEl: ".meguriNigiri02--next",
    },
  });

}

if (hitotsu) {
  header.classList.add("open");

  if (window.innerWidth > 768) {
    header.classList.remove("open");
  }

  if (window.innerWidth < 768) {
    document.querySelector(".header__titleText").classList.add("show")
    document.querySelector(".header__titleText").classList.add("top");

    if (window.scrollY > 5) {
      document.querySelector(".header__titleText").classList.remove("show");
      document.querySelector(".header__titleText").classList.remove("top");
      header.classList.remove("open");
    }

    window.addEventListener("scroll", function () {
      const headerTitleText = document.querySelector(".header__titleText");
      if (window.scrollY > 5) {
        headerTitleText.classList.remove("show");
        headerTitleText.classList.remove("top");
        header.classList.remove("open");
      } else if (window.scrollY > 0) {
        headerTitleText.classList.remove("show");
        headerTitleText.classList.add("top");
        header.classList.add("open");
      } else {
        headerTitleText.classList.add("show");
        headerTitleText.classList.add("top");
      }
    });
  }

  const hitotsuSlide = new Swiper("#hitotsuSlide", {
    spaceBetween: 50,
    loop: true,
    loopAdditionalSlides: 1,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    navigation: {
      nextEl: ".hitotsuSlide__button--prev",
      prevEl: ".hitotsuSlide__button--next",
    },
  });

  const moreButtons = document.querySelectorAll(".hitotsuMenu__more");

  moreButtons.forEach((button) => {
    button.addEventListener("click", function () {
      this.classList.toggle("open");
      const menu = this.nextElementSibling;
      menu.classList.toggle("open");
    })
  });

  const modalCloseButtons = document.querySelectorAll(".hitotsuMenuModal__close");

  modalCloseButtons.forEach((button) => {
    button.addEventListener("click", function () {
      const modal = this.closest(".hitotsuMenuModal");
      const more = modal.previousElementSibling;
      console.log(more);
      modal.classList.remove("open");
      more.classList.remove("open");
    });
  });

  const hitotsuDinnerSlide1 = new Swiper("#hitotsuDinner01", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".hitotsuDinner--prev",
      prevEl: ".hitotsuDinner--next",
    },
  });

  const hitotsuDinnerSlide2 = new Swiper("#hitotsuDinner02", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".hitotsuDinner02--prev",
      prevEl: ".hitotsuDinner02--next",
    },
  });

  const hitotsuLunchSlide1 = new Swiper("#hitotsuLunch01", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".hitotsuLunch--prev",
      prevEl: ".hitotsuLunch--next",
    },
  });
  const hitotsuLunchSlide2 = new Swiper("#hitotsuLunch02", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".hitotsuNigiri--prev",
      prevEl: ".hitotsuNigiri--next",
    },
  });

}


if (sekki) {
  const sekkiItems = document.querySelectorAll(".sekki__item");

  sekkiItems.forEach((item) => {
    item.addEventListener("mouseover", function () {
      sekkiItems.forEach((item) => {
        item.querySelector(".sekki__item-hover").classList.remove("active");
      });

      item.querySelector(".sekki__item-hover").classList.add("active");
    });

    item.addEventListener("mouseout", function () {
      sekkiItems.forEach((item) => {
        item.querySelector(".sekki__item-hover").classList.remove("active");
      });
    });
  });
}

if (sekkiProduct) {
  const slideElements = document.querySelectorAll('#sekkiDetailThumbnail .swiper-slide');
  const slideCount = slideElements.length;

  const sekkiDetailThumbnail = new Swiper('#sekkiDetailThumbnail', {
    slidesPerView: slideCount,
  });

  const sekkiDetailSlide = new Swiper("#sekkiDetailSlide", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 2500,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    thumbs: {
      swiper: sekkiDetailThumbnail,
    },
    navigation: {
      nextEl: ".sekkiDetail__link--next",
      prevEl: ".sekkiDetail__link--prev",
    },
  });



  document.getElementById('addToCartButton').addEventListener('click', function () {
    // ボタンのdata属性から商品IDを取得
    var productId = this.getAttribute('data-product-id');

    // WooCommerceのAJAXリクエストを使用して商品をカートに追加
    jQuery.post('/?wc-ajax=add_to_cart', {
      product_id: productId,
      quantity: 1
    }, function (response) {
      if (response.error && response.product_url) {
        // エラーが発生した場合、商品ページにリダイレクト
        window.location = response.product_url;
      } else {
        // カート画面に遷移
        window.location = '/cart';
      }
    });
  });
}

if (cart) {
  setTimeout(() => {
    const shippingCosts = document.querySelectorAll(".wc-block-components-totals-item__value");

    shippingCosts.forEach((item) => {
      let text = item.textContent;
      if (text.includes("無料")) {
        text = text.replace("無料", "");
      }
      item.textContent = text;
    }
    );



    const shippingOptions = document.querySelectorAll(".wc-block-components-shipping-rates-control__package__description--free");

    shippingOptions.forEach((item) => {
      let text = item.textContent;
      if (text.includes("無料")) {
        text = text.replace("無料", "");
      }
      item.textContent = text;
    });
  }, 5000);


}